@import url('https://fonts.googleapis.com/css2?family=Source+Sans+3:wght@400;600;700&display=swap');

html,
body,
#root,
.app,
.content {
    height: 100%;
    width: 100%;
    font-family: 'Source Sans 3', sans-serif;
}

body {
    margin: 0;
    padding: 0;
    line-height: 1.5;
}

.app {
    display: flex;
    height: 100vh;
    width: 100%;
}

.sidebar {
    flex-shrink: 0;
    z-index: 1000;
}

.content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    box-sizing: border-box;
}

.content.with-topbar {
    padding: 16px;
    padding-top: 64px; /* Apply padding only when Topbar is present */
}

.topbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 16px;
    background-color: var(--topbar-bg-color);
    height: 64px;
    box-sizing: border-box;
}

.topbar .MuiIconButton-root {
    padding: 8px; /* Ensures the icon button is clickable across the entire area */
}

.sidebar .pro-sidebar-inner {
    display: flex;
    flex-direction: column;
    height: 100%;
    box-sizing: border-box;
}

.sidebar .pro-icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.sidebar .pro-inner-item {
    display: flex;
    align-items: center;
    padding: 8px 16px; /* Ensure consistent padding */
    box-sizing: border-box;
}

.sidebar .pro-menu-item {
    display: flex;
    align-items: center;
    padding: 8px 16px; /* Ensure consistent padding */
    box-sizing: border-box;
}

/* Custom scrollbar styles */
::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background: #e0e0e0;
}

::-webkit-scrollbar-thumb {
    background: #888;
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .app {
        flex-direction: column;
    }

    .sidebar {
        display: none;
    }

    .topbar {
        padding: 8px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    }

    .content {
        padding-top: 64px;
        padding-left: 8px;
        padding-right: 8px;
    }
}
